@import url("http://fonts.googleapis.com/css?family=Belleza");
@import 'unsemantic-grid-responsive.css';
article,aside,details,figcaption,figure,footer,header,hgroup,main,nav,section,summary{display:block}
audio,canvas,video{display:inline-block}
audio:not([controls]){display:none;height:0}
[hidden]{display:none}
html{font-family:sans-serif;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%}
body{margin:0}
a:focus{outline:thin dotted}
a:active,a:hover{outline:0}
h1{font-size:2em;margin:.67em 0}
abbr[title]{border-bottom:1px dotted}
b,strong{font-weight:bold}
dfn{font-style:italic}
hr{-moz-box-sizing:content-box;box-sizing:content-box;height:0}
mark{background:#ff0;color:#000}
code,kbd,pre,samp{font-family:monospace,serif;font-size:1em}
pre{white-space:pre-wrap}
q{quotes:"\201C" "\201D" "\2018" "\2019"}
small{font-size:80%}
sub,sup{font-size:75%;line-height:0;position:relative;vertical-align:baseline}
sup{top:-.5em}
sub{bottom:-.25em}
img{border:0}
svg:not(:root){overflow:hidden}
figure{margin:0}
fieldset{border:1px solid #c0c0c0;margin:0 2px;padding:.35em .625em .75em}
legend{border:0;padding:0}
button,input,select,textarea{font-family:inherit;font-size:100%;margin:0}
button,input{line-height:normal}
button,select{text-transform:none}
button,html input[type="button"],input[type="reset"],input[type="submit"]{-webkit-appearance:button;cursor:pointer}
button[disabled],html input[disabled]{cursor:default}
input[type="checkbox"],input[type="radio"]{box-sizing:border-box;padding:0}
input[type="search"]{-webkit-appearance:textfield;-moz-box-sizing:content-box;-webkit-box-sizing:content-box;box-sizing:content-box}
input[type="search"]::-webkit-search-cancel-button,input[type="search"]::-webkit-search-decoration{-webkit-appearance:none}
button::-moz-focus-inner,input::-moz-focus-inner{border:0;padding:0}
textarea{overflow:auto;vertical-align:top}
table{border-collapse:collapse;border-spacing:0}
body{background:url("../images/background.png");border-top:5px solid #000;font-family:'Belleza'}
h1,h2,h3,h4{font-family:'Belleza';margin:0}
header a{text-decoration:none;-webkit-transition:all .25s;-moz-transition:all .25s;-ms-transition:all .25s;-o-transition:all .25s;transition:all .25s}
header h1{font-size:1.8em;line-height:1em;margin:10px 0 20px 0;display:inline-block;vertical-align:top;zoom:1;*display:inline;padding:0;float:left;}
header h1 a{color:#000;font-size:1.2em;}
header h1 a small{display:block;color:#838383;font-size:.7em}
header h1 a:hover{text-decoration:none;color:#838383}
header nav{float:right;display:inline-block;vertical-align:top;zoom:1;*display:inline;}
header nav ul{margin:0;padding:0}
header nav li{display:inline-block;vertical-align:top;zoom:1;*display:inline;}
header nav li a{background-color:transparent;-webkit-box-sizing:border-box;-moz-box-sizing:border-box;-ms-box-sizing:border-box;-o-box-sizing:border-box;box-sizing:border-box;color:#838383;display:block;font-weight:bold;height:86px;line-height:86px;padding:0 1em;vertical-align:middle;}
header nav li a:hover,header nav li a.on{background-color:#ffcd00;color:#000}
header nav .collapse-trigger{display:none}
.banner{background:url("../images/background-banner.png");}
.banner div{background:url("../images/banner.png") no-repeat center 10px;height:203px;}
.banner div p{padding-top:3.5em;font-size:1.2em;color:#999}
section{min-height:400px;padding:2em 0;}
section h1{margin-bottom:.8em}
article{padding-bottom:40px;}
article a{color:#ffcd00;text-decoration:none;}
article a:hover{text-decoration:underline}
article h4{border-left:5px solid #ffcd00;padding-left:.5em;margin-top:2em}
article img{margin:1em auto;border:1px solid #fff;-webkit-box-shadow:0 0 8px rgba(0,0,0,0.5);-moz-box-shadow:0 0 8px rgba(0,0,0,0.5);-ms-box-shadow:0 0 8px rgba(0,0,0,0.5);-o-box-shadow:0 0 8px rgba(0,0,0,0.5);box-shadow:0 0 8px rgba(0,0,0,0.5)}
article strong{border-bottom:1px dotted #000}
article blockquote{border-left:3px solid #e0e0e0;margin:0 .8em;padding:0 1.5em;font-style:italic;font-size:1.1em}
aside img{max-width:180px;width:90%}
aside nav{background:url("../images/background-menu2.png") repeat-y top right;}
aside nav ul,aside nav li{margin:0;padding:0;list-style:none;}
aside nav ul a,aside nav li a{color:#000;display:block;font-family:'Belleza';padding:8px 15px 8px 8px;text-decoration:none;}
aside nav ul a:hover,aside nav li a:hover{color:#999}
aside nav ul a.on,aside nav li a.on{background-color:#ccc;color:#fff}
aside nav ul{padding-bottom:73px;background:url("../images/background-menu.png") no-repeat bottom right}
form{margin:0}
fieldset{padding:0;margin:0;border:0;width:100%}
label{display:block;margin-bottom:5px;}
label.error{font-size:.9em;color:#f00;margin-top:-5px}
label abbr{font-size:.8em;color:#cbcbca}
input,textarea{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;-ms-box-sizing:border-box;-o-box-sizing:border-box;box-sizing:border-box;display:inline-block;color:#666664;height:1.5em;line-height:1.5em;padding:4px 12px;margin-bottom:.8em;height:2em;vertical-align:middle;width:100%;-webkit-border-radius:3px;-moz-border-radius:3px;-ms-border-radius:3px;-o-border-radius:3px;border-radius:3px;vertical-align:middle;border:1px solid #e5e5e4;-webkit-box-shadow:inset 0 1px 1px rgba(0,0,0,0.075);-moz-box-shadow:inset 0 1px 1px rgba(0,0,0,0.075);-ms-box-shadow:inset 0 1px 1px rgba(0,0,0,0.075);-o-box-shadow:inset 0 1px 1px rgba(0,0,0,0.075);box-shadow:inset 0 1px 1px rgba(0,0,0,0.075);-webkit-transition:all .25s;-moz-transition:all .25s;-ms-transition:all .25s;-o-transition:all .25s;transition:all .25s;}
input:focus,textarea:focus{border-color:rgba(82,168,236,0.8);outline:0;-webkit-box-shadow:inset 0 1px 1px rgba(0,0,0,0.075);-moz-box-shadow:inset 0 1px 1px rgba(0,0,0,0.075);-ms-box-shadow:inset 0 1px 1px rgba(0,0,0,0.075);-o-box-shadow:inset 0 1px 1px rgba(0,0,0,0.075);box-shadow:inset 0 1px 1px rgba(0,0,0,0.075)}
input.error,textarea.error{border:1px solid #f00}
textarea{resize:none;height:7em;line-height:normal}
button{background-color:#898988;background-image:-moz-linear-gradient(top,#c1c1c0,#898988);background-image:-webkit-gradient(linear,0 0,100% 0,from(#c1c1c0),to(#898988));background-image:-webkit-linear-gradient(top,#c1c1c0,#898988);background-image:-o-linear-gradient(top,#c1c1c0,#898988);background-image:linear-gradient(to bottom,#c1c1c0,#898988);background-repeat:repeat-x;border:1px solid #cbcbca;-webkit-border-radius:5px;-moz-border-radius:5px;-ms-border-radius:5px;-o-border-radius:5px;border-radius:5px;color:#fff;padding:.3em 1em;text-transform:uppercase;font-size:.8em;-webkit-box-shadow:0 3px 8px rgba(0,0,0,0.3);-moz-box-shadow:0 3px 8px rgba(0,0,0,0.3);-ms-box-shadow:0 3px 8px rgba(0,0,0,0.3);-o-box-shadow:0 3px 8px rgba(0,0,0,0.3);box-shadow:0 3px 8px rgba(0,0,0,0.3);}
button:hover{background-color:#c1c1c0;background-image:-moz-linear-gradient(top,#898988,#c1c1c0);background-image:-webkit-gradient(linear,0 0,100% 0,from(#898988),to(#c1c1c0));background-image:-webkit-linear-gradient(top,#898988,#c1c1c0);background-image:-o-linear-gradient(top,#898988,#c1c1c0);background-image:linear-gradient(to bottom,#898988,#c1c1c0);background-repeat:repeat-x}
button[disabled]{opacity:.5;filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=50)}
.alert{padding:1em;margin-bottom:30px;background:#e0e0e0;border-left:5px solid #f00}
.error{border-color:#f00}
.success{border-color:#008000}
footer{background-color:#000;color:#ffcd00;font-size:.8em;padding:2em 0 2.5em 0;}
footer a{color:#ffcd00}
.align-center{text-align:center}
.align-left{text-align:left}
.align-right{text-align:right}
@media screen and (max-width: 950px){section img{width:100%}
}@media screen and (max-width: 800px){header h1{font-size:1em}
header nav{float:left;}
header nav ul li{max-width:30%;}
header nav ul li a{font-size:.8em;height:60px;line-height:normal;padding:1em .5em}
}